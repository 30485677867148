import React from "react";
import Logo from "../images/Logo.svg";
import "./Landing.scss";
import { NavHashLink } from "react-router-hash-link";

const LandingPage = () => {
  return (
    <div>
      <div id="teaser" data-aos="zoom-in">
        <img src={Logo} id="Logo" alt="iWeb4you"></img>
        <h1>Der richtige Partner für ihre Webseite oder App</h1>
        <NavHashLink
          className="button"
          smooth
          to={{ pathname: "/websites", hash: "#Leistungen" }}
        >
          Jetzt mehr erfahren
        </NavHashLink>
      </div>
    </div>
  );
};

export default LandingPage;
