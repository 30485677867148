import React, { Fragment } from "react";
import { Canvas } from "../UI";
import Footer from "../Footer";

const Impressum = () => {
  return (
    <Fragment>
      <Canvas leftbound>
        <h2>Impressum</h2>
        <hr></hr>

        <h3>Angaben gem&auml;&szlig; &sect; 5 TMG</h3>
        <p>
          Philipp Sebastian Vincon
          <br />
          iWeb4You - Webseiten und Apps
          <br />
          Eduard-Presser-Straße 9
          <br />
          78247 Hilzingen-Riedheim
        </p>

        <h3>Kontakt</h3>
        <p>
          Telefon: +49 (0) 15228819774
          <br />
          Telefax: +49 (0) 15228819774
          <br />
          E-Mail: philipp@iWeb4you.de
        </p>

        <h3>Umsatzsteuer-ID</h3>
        <p>
          Umsatzsteuer-Identifikationsnummer gem&auml;&szlig; &sect; 27 a
          Umsatzsteuergesetz:
          <br />
          DE 290 238 828
        </p>
      </Canvas>
      <Footer></Footer>
    </Fragment>
  );
};

export default Impressum;
