import React, { useEffect } from "react";
import "./App.scss";
import "./Animations.scss";
import Navigation from "./Navigation";
import Section from "./Section";
import AOS from "aos";
import "aos/dist/aos.css";
import LandingPage from "./pages/Landing";
import Leistungen from "./pages/Leistungen";
import About from "./pages/About";
import Kontakt from "./pages/Kontakt";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Datenschutzerklaerung from "./pages/Datenschutz";
import Haftungsausschluss from "./pages/Haftungsausschluss";
import Impressum from "./pages/Impressum";

function App() {
  useEffect(() => {
    AOS.init({
      duration: 300,
      once: false,
      delay: 50,
      easing: "ease-in-out",
      mirror: true
    });
  }, []);

  return (
    <div className="App">
      <Router>
        <Switch>
          <Route exact path={["/", "/websites", "/apps"]}>
            <Navigation behavior="scroll"></Navigation>
            <Section title="LandingPage" animation="background">
              <LandingPage></LandingPage>
            </Section>
            <Section title="Leistungen">
              <Leistungen></Leistungen>
            </Section>
            <Section title="About">
              <About></About>
            </Section>
            <Section title="Kontakt">
              <Kontakt></Kontakt>
            </Section>
          </Route>
          <Route path="/impressum">
            <Navigation behavior="fixed"></Navigation>
            <Section title="Impressum">
              <Impressum></Impressum>
            </Section>
          </Route>
          <Route path="/datenschutz">
            <Navigation behavior="fixed"></Navigation>
            <Section title="Datenschutz">
              <Datenschutzerklaerung></Datenschutzerklaerung>
            </Section>
          </Route>
          <Route path="/haftungsausschluss">
            <Navigation behavior="fixed"></Navigation>
            <Section title="Haftungsausschluss">
              <Haftungsausschluss></Haftungsausschluss>
            </Section>
          </Route>
        </Switch>
      </Router>
    </div>
  );
}

export default App;
