import React, { useEffect, useState } from "react";
import { NavHashLink } from "react-router-hash-link";
import Logo from "./images/Logosmall.png";
import Menu from "@mui/icons-material/Menu";
import "./Navigation.scss";

const Navigation = ({ behavior }) => {
  const [fixed, setFixed] = useState(false);
  useEffect(() => {
    window.addEventListener("scroll", () => {
      setFixed(window.scrollY > document.body.clientHeight);
    });
  }, []);

  const [collapsed, setCollapsed] = useState(true);

  return (
    <div className={fixed || behavior === "fixed" ? "navfixed" : "navscroll"}>
      <nav className={collapsed ? "collapsed" : "open"}>
        {fixed}
        <NavHashLink smooth to={{ pathname: "/", hash: "LandingPage" }}>
          <img src={Logo} id="Logosmall" alt="iWeb4you"></img>
        </NavHashLink>
        <button
          id="toggle"
          onClick={() => {
            setCollapsed(!collapsed);
          }}
        >
          <Menu fontSize="4rem"></Menu>
        </button>
        <ul id="menu">
          <li>
            <NavHashLink smooth to={{ pathname: "/", hash: "LandingPage" }}>
              Startseite
            </NavHashLink>
          </li>
          <li>
            <NavHashLink smooth to={{ pathname: "/apps", hash: "#Leistungen" }}>
              Dienstleistungen
            </NavHashLink>
          </li>
          <li>
            <NavHashLink smooth to={{ pathname: "/", hash: "#About" }}>
              About
            </NavHashLink>
          </li>
          <li>
            <NavHashLink smooth to={{ pathname: "/", hash: "#Kontakt" }}>
              Kontakt
            </NavHashLink>
          </li>
        </ul>
      </nav>
    </div>
  );
};

export default Navigation;
