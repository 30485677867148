import React, { Fragment } from "react";
import "./About.scss";
import { Canvas } from "../UI";
const About = () => {
  return (
    <Fragment>
      <Canvas>
        <div data-aos="fade" className="heading">
          <h2>Über mich</h2>
          <hr />
        </div>
        <div>
          <p data-aos="fade">
            Ein paar Worte zu meiner Person: Schon zu Studienzeiten der
            technischen Informatik vor 10 Jahren beschäftigte ich mich mit der
            kommerziellen Erstellung von Homepages und Webseiten. Getreu dem
            Motto "Man lernt nie aus!" habe ich mir kontinuierlich weiter Wissen
            angeeignet.
          </p>
          <p data-aos="fade">
            Dies gibt mir die Möglichkeit auf einen breit gefächerten Design-
            und Technologiebaukasten zurückzugreifen um Sie bei Ihrem Projekt
            bestmöglich zu unterstützen.
          </p>
          <p data-aos="fade">
            Die Nähe zum Kunden ist der Schlüssel für ein erfolgreiches Projekt
            und eine langfristige Partnerschaft. Ich bin in Singen am
            Hohentwiel, im Hegau und Bodensee Gebiet für Sie vor Ort und freue
            mich auf Ihre Anfrage.
          </p>
        </div>
      </Canvas>
    </Fragment>
  );
};

export default About;
