import React, { Fragment } from "react";
import "./Leistungen.scss";
import { Canvas } from "../UI";

const Leistungen = () => {
  return (
    <Fragment>
      <div data-aos="fade">
        <h2>Dienstleistungen</h2>
        <hr />
      </div>
      <h3 className="heading">
        Die Perfekte App oder Homepage genau für Ihr Anforderungsprofil.
        Profitieren Sie von einem Full-Stack Web-Entwicklungsprozess inklusive
        Betreuung und Support
      </h3>
      <Canvas>
        <ul>
          <li data-aos="fade">
            <h3>UX Design</h3>
            <p>
              Der erste Schritt zu Ihrer neuen Homepage oder App besteht darin
              ihre Anforderungen aufzunehmen und diese in einem modernen,
              creativen Design zu verankern. Dabei steht die "User Experience"
              an erster Stelle.
            </p>
          </li>
          <li data-aos="fade">
            <h3>Webseiten und App Entwicklung</h3>
            <p>
              Zugeschnitten auf Ihre Anforderung bekommen Sie ihr Projekt auf
              dem neuesten technischen Stand umgesetzt. Ich bin insbesondere auf
              die Entwicklung von Webseiten, iOS und Android sowie webbasierten
              Apps spezialisiert
            </p>
          </li>
          <li data-aos="fade">
            <h3>SEO</h3>
            <p>
              Schon bei der Umsetzung wird darauf geachtet, dass sie im Web
              gefunden werden. Dazu zählt auch, dass gängige Technologien und
              Optimierungen beachtet werden damit Sie am Ende ganz Oben in den
              Suchmaschinen stehen!
            </p>
          </li>

          <li data-aos="fade">
            <h3>Hosting und Support</h3>
            <p>
              Nach der Umsetzung beginnt die Pflege. Auf Wunsch wird ihre
              Webseite nach der Ersterstellung selbstverständlich weiter
              betreut. Neben der Bereitstellung bei einem Hosting-Partner
              gehören dazu Softwareupdates aber auch inhaltliche Änderungen.
            </p>
          </li>
        </ul>
      </Canvas>
      <div className="quote">
        <q data-aos="zoom-in">
          Menschen mit einer neuen Idee gelten solange als Spinner, bis sich die
          Sache durchgesetzt hat.
        </q>
        <span data-aos="zoom-in">Mark Twain, Schriftsteller</span>
      </div>
    </Fragment>
  );
};

export default Leistungen;
