import React from "react";

const Section = props => {
  return (
    <section
      id={props.title}
      data-aos={props.animation}
      data-aos-duration="800"
    >
      {props.children}
    </section>
  );
};

export default Section;
