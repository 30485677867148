import React from "react";

const Canvas = props => {
  return (
    <div className={`canvas ${props.leftbound ? "left" : ""}`}>
      {props.children}
    </div>
  );
};

export { Canvas };
