import React from "react";
import { Canvas } from "./UI";

import { NavHashLink } from "react-router-hash-link";

import IconFacebook from "./images/Icon_Facebook.svg";
import IconInstagram from "./images/Icon_Instagram.svg";
import IconLinkedIn from "./images/Icon_LinkedIn.svg";
import IconWhatsapp from "./images/Icon_Whatsapp.svg";
import IconXing from "./images/Icon_Xing.svg";

import "./Footer.scss";

const Footer = () => {
  return (
    <Canvas>
      <footer>
        <div className="footerIcons" data-aos="fade">
          <img src={IconFacebook} alt="Facebook"></img>
          <img src={IconInstagram} alt="Instagram"></img>
          <img src={IconLinkedIn} alt="LinkedIn"></img>
          <a href="https://wa.me/4915228819774">
            <img src={IconWhatsapp} alt="Whatsapp"></img>
          </a>
          <a href="https://www.xing.com/profile/Philipp_Vincon2/cv">
            <img src={IconXing} alt="Xing"></img>
          </a>
        </div>
        <div className="footerNav">
          <NavHashLink to={{ pathname: "/impressum" }}>Impressum</NavHashLink>
          <NavHashLink to={{ pathname: "/datenschutz" }}>
            Datenschutz
          </NavHashLink>
          <NavHashLink to={{ pathname: "/haftungsausschluss" }}>
            Haftungsausschluss
          </NavHashLink>
        </div>
        <div className="footerRef">
          Copyright by <a href="/">iWeb4You.de</a>
        </div>
      </footer>
    </Canvas>
  );
};

export default Footer;
