import React, { Fragment } from "react";
import { Canvas } from "../UI";
import "./Kontakt.scss";

import IconEmail from "../images/Icon_Email.svg";
import IconPhone from "../images/Icon_Phone.svg";
import IconLocation from "../images/Icon_Location.svg";
import Footer from "../Footer";

export default function Kontakt() {
  return (
    <Fragment>
      <div>
        <h2 data-aos="fade">Kontakt</h2>
        <hr data-aos="fade"></hr>
      </div>
      <Canvas>
        <p className="slogan" data-aos="fade">
          Sie benötigen meine Unterstützung?
          <br />
          Kontaktieren Sie mich für ein unverbindliches Erstgespräch.
        </p>
      </Canvas>
      <div className="contactBG" data-aos="fade">
        <p>
          <img src={IconEmail} alt="Email:"></img>
          <a href="mailto:philipp@iweb4you.com?subject=Projektanfrage%20an%20iWeb4you">
            philipp@iweb4you.com
          </a>
        </p>
        <p>
          <img src={IconLocation} alt="Location:"></img> Bergstraße 34 , 78244
          Gottmadingen
        </p>
        <p>
          <img src={IconPhone} alt="Phone:"></img>
          <a href="tel:+4915228819774">01522 88 19 774</a>
        </p>
      </div>
      <Footer></Footer>
    </Fragment>
  );
}
